<template>
  <main>
    <section class="container" style="margin-top:50px">
      <section-title>
        <div class="d-flex align-items-center">
          {{ $t('home.explore.h') }}
          <drop-down class="ml-4" v-model="itemType" :data="itemTypes">
            <template v-slot:title="{ selected }">
              <all-svg />
              <span>{{ selected.label }}</span>
            </template>
            <template v-slot:all>
              <all-solid-svg class="mr-2" />
            </template>
          </drop-down>

          <!-- <drop-down-multiple-select-new class="ml-4" multi-select v-model="drop5" :data="options5">
            <template v-slot:title>
              <blockchain-svg />
              <span>Block Chain</span>
            </template>
          </drop-down-multiple-select-new> -->

          <drop-down-price-range class="ml-4" v-model="priceRange" />
        </div>
        <drop-down class="ml-auto" v-model="priceSort" :data="priceSortOptions">
          <template v-slot:title="{ selected }">
            <sort-down-svg v-if="priceSort.value === 'htl'" />
            <sort-up-svg v-else />
            {{ selected.label }}
          </template>
        </drop-down>
      </section-title>

      <div class="d-flex justify-content-between flex-wrap my-4">
        <small v-if="filteredItems" style="line-height:32px" class="text-secondary">
          总共 {{ total }} 件饰品
        </small>
        <b-pagination
          class="m-0"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          first-number
          last-number
        />
      </div>

      <div v-if="loading" class="card-box mt-1">
        <div v-for="i in 8" :key="i" class="card-item" style="padding:10px">
          <b-skeleton class="m-0" style="border-radius: 32px;" width="" height="420px" />
        </div>
      </div>

      <div v-else class="card-box mt-1">
        <div
          v-if="filteredItems && filteredItems.length === 0"
          class="d-flex justify-content-center align-items-center text-secondary w-full"
          style="height:300px"
        >
          没有找到相关装备~
        </div>
        <collection-weapon
          class="card-item"
          v-for="(i, k) in filteredItems"
          :key="k + 'cs'"
          :item="i"
          @click="$router.push(`/store/${i.market_hash_name}`)"
        />
        <!-- <nft-card-detailed class="card-item" v-for="(i, k) in filteredItems" :key="k + 'a'" :item="i" /> -->
      </div>

      <!-- <div class="px-2 mt-3">
        <btn-color class="btn-outline-primary w-full">{{ $t('collections.ite.load') }}</btn-color>
      </div> -->
    </section>
  </main>
</template>

<script>
import { StoreService } from '@/services/api'

export default {
  name: '',
  components: {},
  data() {
    return {
      priceRange: { type: 'usd', min: '', max: '' },
      priceSort: { label: this.$t('home.explore.menu4.htl'), value: 'htl' },
      itemType: { label: this.$t('home.explore.menu1.all'), value: 'all' },
      itemTypes: [
        { label: this.$t('home.explore.menu1.all'), value: 'all' },
        { label: '消费级', value: '消费级' },
        { label: '军规级', value: '军规级' },
        { label: '工业级', value: '工业级' },
        { label: '匕首', value: '匕首' },
        { label: '受限', value: '受限' },
        { label: '保密', value: '保密' },
        { label: '隐秘', value: '隐秘' },
        { label: '普通级', value: '普通级' },
        { label: '高级', value: '高级' },
        { label: '非凡', value: '非凡' },
        { label: '奇异', value: '奇异' },
        { label: '卓越', value: '卓越' },
        { label: '违禁', value: '违禁' },
      ],
      priceSortOptions: [
        { label: this.$t('home.explore.menu4.htl'), value: 'htl' },
        { label: this.$t('home.explore.menu4.lth'), value: 'lth' },
      ],
      items: [],
      loading: false,
      currentPage: 1,
      total: 0,
      perPage: 50,
    }
  },
  computed: {
    filteredItems() {
      let rv = this.items
      if (this.priceSort.value === 'htl') {
        rv = rv.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
      }
      if (this.priceSort.value === 'lth') {
        rv = rv.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
      }
      if (this.priceRange) {
        if (this.priceRange.min) {
          rv = rv.filter(i => parseFloat(i.price) >= this.priceRange.min)
        }
        if (this.priceRange.max) {
          rv = rv.filter(i => parseFloat(i.price) <= this.priceRange.max)
        }
      }
      if (this.itemType && this.itemType.value !== 'all') {
        rv = rv.filter(i => i.type.includes(this.itemType.value))
      }
      return rv
    },
  },
  created() {
    this.loadWeapons()
  },
  watch: {
    currentPage(page) {
      this.loadWeapons(page)
    },
  },
  methods: {
    async loadWeapons(page) {
      this.loading = true
      const { data } = await StoreService.getStore(page)
      this.items = data.data.map(i => {
        const rv = i.item
        rv.price = i.price
        return rv
      })
      this.perPage = data.per_page
      this.total = data.total
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
